<template>
	<v-container fluid style="max-width: 1200px">
		<DegreeAdd :flat="true" />
	</v-container>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: this.$t('studies.addNewDegree'),
		}
	},
	components: {
		DegreeAdd: () => import('@/components/studies/DegreeAdd.vue'),
	},
}
</script>
